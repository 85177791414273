/* 04.11 Team CSS */

.team {
  .thumb {
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%;
      min-height: 270px;
      cursor: pointer;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      padding: 30px;
      opacity: 0;
      visibility: hidden;
      background-blend-mode: multiply;
      background-size: cover;
      background-color: var(--clr-primary);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }

    .social-icon {
      position: absolute;
      bottom: 33px;
      left: 0;
      padding: 0 30px;
      font-size: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-5px);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      right: 0;
      margin: 0 -15px;

      li {
        margin: 0;
        display: inline-block;

        a {
          transition: all .3s cubic-bezier(.645, .045, .355, 1);
          display: inline-block;
          font-size: 18px;
          color: #222;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          background: var(--clr-white);
          margin: 7px;

          &:hover {
            background-color: var(--clr-primary);
            color: var(--clr-white);
          }
        }
      }
    }

  }

  .team-info {
    .info {
      h4 {
        font-size: 22px;
        line-height: 1.388;
        margin-bottom: 6px;
        color: var(--clr-primary);
      }

      span {
        font-weight: bold;
        letter-spacing: 0em;
        line-height: 1.58;
        font-size: 18px;
        color: var(--clr-body);
      }
    }
  }

  &:hover {
    .thumb {
      img {}

      .overlay {
        opacity: 0.3;
        visibility: visible;
      }

      .social-icon {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

      }

      .more {
        background-color: green;
        height: 100px;
        width: 100px;

      }

    }
  }
}

$popup-content-max-height: 75vh;

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 80% !important;
  padding: 5px;
  max-height: $popup-content-max-height;
  height: 100%;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.modal {
  font-size: 12px;
}

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
}

.modal>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.poptext {

  .popcontainer {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    justify-content: center;
  }

  .image {
    max-width: 220px;
    max-height: 220px;
    margin: 10px;
  }

  .text {
    font-size: 20px;
    padding: 10px;
    overflow-y: auto;
  }

  @media #{$tablet-device, $large-mobile} {
    .image {
      max-width: 220px;
      max-height: 220px;
    }

    .text {
      font-size: 16px;
      max-height: calc($popup-content-max-height - 260px);
    }
  }
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}