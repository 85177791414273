/* 06.01. Home One Intro CSS */


.intro-section {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 50px 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    & .container {
        z-index: 9;
    }

    @media #{$tablet-device} {
        height: 100%;
        min-height: 500px;
    }
    @media #{$large-mobile} {
        height: 100%;
        min-height: 500px;
    }
}
.bg-video{
    div{
        width: 100% !important;
    }
}
.Player__videoContainer___2TVqS{
    left: 0 !important;
}

.intro-content {
    max-width: 760px;
    & .title {
        font-size: 50px;
        line-height: 1.2;
        color: var(--clr-white);
        margin-bottom: 40px;
    }

    & .desc {
        max-width: 700px;

        & p {
            // font-size: 28px;
            font-weight: 500;
            line-height: 1.67;
            color: var(--clr-white);
        }
    }

    & .btn {
        margin: 0 5px;
        margin-top: 60px;
    }

    @media #{$tablet-device,
    $large-mobile
    } {
        & .title {
            font-size: 33px;
            margin-bottom: 20px;
        }

        & .desc {
            & p {
                font-size: 20px;
            }
        }
        & .btn {
            margin-top: 20px;
        }
    }

    @media #{$extra-small-mobile} {

        & .title {
            font-size: 33px;
        }

        & .desc {
            & p {
                font-size: 18px;
            }
        }
    }
}

#example_video_1 {
    max-width: 100%;
    max-height: 400px;
}
@media #{$tablet-device, $small-mobile} {
    #example_video_1 {
        max-width: 100%;
        max-height: 200px;
    } 
}
@media #{$desktop-device} {
    #example_video_1 {
        max-width: 100%;
        max-height: 300px;
    }
}

.intro-content {

    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}

.intro-slider{
    &:hover{
        .swiper-btn{
            opacity: 1;
            visibility: visible;
        }
    }
}
.intro-content {

        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.5s;
            }
            &:nth-child(4) {
                animation-delay: 2s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
            
        }
    }





