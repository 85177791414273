/* 05.03 Mobile Menu CSS */

.site-main-mobile-menu {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100%;
    height: 100%;

    transition: var(--transition);

    opacity: 0;
    background: rgba(var(--clr-black-rgb), 0.7);
    &.active {
        visibility: visible;
        opacity: 1;
        .site-main-mobile-menu-inner{
            transform: translateX(0);
        }
    }
}

.site-main-mobile-menu-inner {
    position: relative;
    z-index: 9;

    float: right;

    width: 100%;
    height: 100%;

    transition: var(--transition);
    transform: translateX(100%);

    background-color: var(--clr-white);
    background-image: url("../../images/bg/mobile-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;

    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: "";

        background-color: rgba(var(--clr-body-rgb), 0.9);
    }
}

#page {
    position: relative;

    transition: var(--transition);
}

.mobile-menu-open {
    overflow: hidden;

    & #page {
        transform: translateX(-360px);
    }

    & .site-main-mobile-menu {
        visibility: visible;

        opacity: 1;
    }

    & .site-main-mobile-menu-inner {
        transform: translateX(0);
    }
}

.mobile-menu-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    height: 80px;
    padding: 0 5px 0 5px;

    background-color: var(--clr-heading);
}

.mobile-menu-logo {
    & a {
        width: 158px;
    }
}

.mobile-menu-close {
    padding: 0;

@extend .header-mobile-menu-toggle;

    & .toggle {
        position: relative;

        width: 40px;
        height: 40px;

        & i {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 29px;
            height: 2px;
            margin: 0 !important;

            transform-origin: center;

            &.icon-top {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &.icon-bottom {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &:hover {
            & i {
                &::before,
                &::after {
                    background-color: var(--clr-white);
                }
            }
        }
    }
}

.mobile-menu-content {
    overflow-y: auto;

    height: calc(100% - 80px);
    padding: 20px 30px 100px;
}

.site-mobile-menu {
    & > ul {
        margin: 0;
        padding-left: 0;

        list-style: none;

        & > li {
            position: relative !important;

            & + li {
                & > a {
                    border-top: 1px solid rgba(var(--clr-white-rgb), 0.15);
                }
            }

            & > a {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.5;

                display: block;

                padding: 15px 0;

                color: var(--clr-white);
                border-bottom: 1px solid transparent;
            }

            & .menu-toggle {
                position: absolute;
                top: 11px;
                right: 0;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 40px;
                height: 40px;

                cursor: pointer;

                color: var(--clr-white);
                border-radius: 50%;
                background-color: transparent;

                & i {
                    font-size: 18px;
                    line-height: 1;

                    transition: var(--transition);
                }

                &:hover {
                    background-color: rgba(var(--clr-white-rgb), 0.2);
                }
            }

            &.open {
                & > a {
                    border-bottom-color: rgba(var(--clr-white-rgb), 0.15);
                }

                & > .menu-toggle {
                    background-color: rgba(var(--clr-white-rgb), 0.2);

                    & i {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
    }

    & .sub-menu, .mega-menu {
        display: none;

        margin: 14px 0;
        padding-left: 0;

        list-style: none;

        & li {
            position: relative;

            & > a {
                font-size: 15px;
                font-weight: 500;
                line-height: 1.5;

                padding-top: 10px;
                padding-right: 0;
                padding-bottom: 10px;
                padding-left: 0;

                color: rgba(var(--clr-white-rgb), 0.7);
                &:hover{
                    color: var(--clr-white);
                }
            }

            & .menu-toggle {
                top: 1px;
            }

            &.open {
                & > a {
                    color: var(--clr-white);
                }

                & > .menu-toggle {
                    background-color: rgba(var(--clr-white-rgb), 0.2);

                    & i {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
        & ul{
            list-style: none;
            margin: 0;
            padding-left: 0;
            list-style: none;
            li{
                a{
                    .badge{
                        background-color: transparent;
                        background-image: linear-gradient(45deg,#fe378c 0%,#fe5b34 100%);
                        display: inline-block;
                        border-radius: 2px;
                        padding: 4px 8px 3px;
                        color: var(--clr-white);
                        font-size: 11px;
                        line-height: 1;
                        font-weight: 700;
                        text-transform: uppercase;
                        letter-spacing: .5px;
                        margin: 0 0 0 7px;
                        &.primary{
                            background-image: none;
                            background-color: var(--clr-primary) !important;
                        }
                    }
                }
            }
        }

        & .sub-menu {
            padding-left: 15px;
        }
    }
}