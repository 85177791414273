.cookie-overlay {
    z-index: 99999 !important;
}
.CookieConsent {
    border-radius: 5px;
    left: 13%!important;
    margin: 10px;
    padding: 20px;
    width: 74%!important;
    background-color: #ffff!important;
    @media #{$large-mobile} {
        margin: 0px;
        margin-bottom: 10px;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
      
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
      
    ::-webkit-scrollbar-thumb {
        background-color: #d8d8d8;
        border-radius: 20px;
    }

    div {
        flex: unset !important;
        margin: 0 !important;
    }

    a{
        text-decoration: underline
        color(255, 200, 100)
    }

    div:empty{
        color: #0000!important;
        background-color: #ffff;
    }
    .cookie-content {
        max-height: 300px;
        overflow: auto;
        padding: 5px;
    }
    
}

#rcc-decline-button{
    background-color: #fff !important;
    border: 1px solid #1976d2 !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 5px rgba(7, 25, 52, 0.2) !important;
    color: #1976d2 !important;
    font-size: 14px !important;
    height: 40px;
    padding: 0 5px !important;
    margin: 0 10px 0 0!important;
    @media #{$large-mobile} {
        margin: 0 0 10px 0!important;
        width: 100% !important;
    }
}

#rcc-confirm-button{
    background-color: #1976d2!important;
    border: none;
    border-radius: 5px!important;
    box-shadow: 0 2px 5px #0b193233!important;
    color: #fff!important;
    height: 40px;
    padding: 0 5px!important;
    margin: 0 !important;
    @media #{$large-mobile} {
        width: 100% !important;
    }
}


