/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color {
  background-color: #030e22;
}

.footer-section {
  font-size: 16px;
  a {
    color: #ffffff;
    &:hover {
      color: var(--clr-primary);
    }
  }
}

.copyright {
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  padding: 20px 15px;
  border-top: 1px solid var(--clr-gray-200);
  color: var(--clr-white);
  margin-bottom: 0;
}